import React from "react";
// Libraries
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { BrowserRouter as Router } from "react-router-dom";

// Core
import {
    getAuthenticated,
    getSessionLoaded,
    getSession,
    getSessionNotFound,
} from "./core/session";

// Components
import Login from "./views/login";
import UserNotFound from "./views/components/pages/user-not-found";
import PageLoading from "./views/components/pages/loading";
import App from "./views/app";

const AuthenticationController = ({
    authenticated,
    loaded,
    sessionNotFound,
}) => {
    // In case it happens that the session and db out out of sync
    if (sessionNotFound) {
        return <UserNotFound />;
    }

    if (authenticated && !loaded) {
        return <PageLoading />;
    }

    if (authenticated && loaded) {
        return <App />;
    }
    // Render the start screen
    return (
        <Router>
            <Login />
        </Router>
    );
};

const mapStateToProps = createSelector(
    getAuthenticated,
    getSessionLoaded,
    getSession,
    getSessionNotFound,
    (authenticated, loaded, session, sessionNotFound) => ({
        authenticated,
        loaded,
        session,
        sessionNotFound,
    })
);

export default connect(mapStateToProps, null)(AuthenticationController);

/*
// QUESTION BUILDER
*/

// import questions from "./Vragen Ondernemers.json";

// import { db } from "./core/firebase";

// const QuestionBuilder = () => {
//     console.log(questions);

//     const batch = db.batch();

//     questions.forEach((item) => {
//         const ref = db.collection("questions").doc(item.id);

//         batch.set(ref, item);
//     });

//     batch.commit();
//     return null;
// };

// export default QuestionBuilder;

/*
// BUILD RESULTS
*/
// import { db } from "./core/firebase";
// import { unwrapList } from "./core/models";

// const Controller = () => {
//     const getSessions = async () => {
//         const questions = await db
//             .collection("questions")
//             .get()
//             .then((payload) => unwrapList(payload, true));

//         const sessions = await db
//             .collection("sessions")
//             .get()
//             .then((payload) => unwrapList(payload));

//         // console.log(questions[0]);
//         // console.log(sessions[0]);
//         // const { answers } = sessions[0];

//         // for (const [key, value] of Object.entries(answers)) {
//         //     const question = questions.find((item) => item.id === key);
//         //     console.log(`${question.title}: ${value}`);
//         // }
//         // // console.log(JSON.stringify(sessions));
//         // sessions.map((item) => {
//         //     const { answers } = item;
//         //     for (const [key, value] of Object.entries(answers)) {
//         //         const question = questions.find((item) => item.id === key);
//         //         item[question.title] = value;
//         //     }

//         //     return item;
//         // });
//         const modifiedList = sessions.reduce((acc, curr) => {
//             const { answers } = curr;
//             let answersWithQuestionKey = {};

//             if (answers) {
//                 console.log("triggered");
//                 for (const [key, value] of Object.entries(answers)) {
//                     const question = questions.find((item) => item.id === key);
//                     answersWithQuestionKey[question.title] = value;
//                 }

//                 return [...acc, { ...curr, answersWithQuestionKey }];
//             }

//             return [...acc, curr];
//         }, []);

//         console.log(modifiedList);
//         console.log(JSON.stringify(modifiedList));
//     };

//     // getSessions();

//     const getMeedenken = async () => {
//         const meedenken = await db
//             .collection("meedenken")
//             .get()
//             .then((payload) => unwrapList(payload));

//         console.log(JSON.stringify(meedenken));
//     };

//     // getMeedenken();

//     const getAanvulling = async () => {
//         const meedenken = await db
//             .collection("aanvulling")
//             .get()
//             .then((payload) => unwrapList(payload));

//         console.log(JSON.stringify(meedenken));
//     };

//     // getAanvulling();
//     getSessions();

//     return null;
// };

// export default Controller;
