export const genders = [
    { value: "Man", label: "Man" },
    { value: "Vrouw", label: "Vrouw" },
    { value: "Anders", label: "Anders" },
];

export const arrangements = [
    { value: "Koop", label: "Koop" },
    { value: "Huur", label: "Huur" },
];

export const companies = [
    {
        value: "Detailhandel",
        label: "Detailhandel",
    },
    {
        value: "Horeca",
        label: "Horeca",
    },
    {
        value: "Kapperszaak",
        label: "Kapperszaak",
    },
    {
        value: "Food",
        label: "Food",
    },
    {
        value: "Mix",
        label: "Mix",
    },
    {
        value: "Culturele instelling",
        label: "Culturele instelling",
    },
    {
        value: "Dienstverlening",
        label: "Dienstverlening",
    },
    {
        value: "Overig",
        label: "Overig",
    },
];

export const owners = [
    {
        value: "Ikzelf en/of mijn partner",
        label: "Ikzelf en/of mijn partner",
    },
    {
        value: "De woningbouwcoöperatie",
        label: "De woningbouwcoöperatie",
    },
    {
        value: "Anders",
        label: "Anders",
    },
];

export const ages = [
    { value: "17 of jonger", label: "17 of jonger" },
    { value: "18-20 jaar", label: "18-20 jaar" },
    { value: "21-29 jaar", label: "21-29 jaar" },
    { value: "30-39 jaar", label: "30-39 jaar" },
    { value: "40-49 jaar", label: "40-49 jaar" },
    { value: "50-59 jaar", label: "50-59 jaar" },
    { value: "60-69 jaar", label: "60-69 jaar" },
    { value: "70 of ouder", label: "70 of ouder" },
];

export const samenstellingen = [
    { value: "Eenpersoonshuishouden", label: "Eenpersoonshuishouden" },
    {
        value: "Meerpersoonshuishouden met kinderen",
        label: "Meerpersoonshuishouden met kinderen",
    },
    {
        value: "Meerpersoonshuishouden zonder kinderen",
        label: "Meerpersoonshuishouden zonder kinderen",
    },
];
